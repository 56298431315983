export const CONTRY_CODE = [{name:'KR'}]

export const REGION_CODE = {
  KR: [
    { code: 'KR-11', name_kr: '서울특별시', name_en: 'Seoul' },
    { code: 'KR-26', name_kr: '부산광역시', name_en: 'Busan' },
    { code: 'KR-27', name_kr: '대구광역시', name_en: 'Daegu' },
    { code: 'KR-28', name_kr: '인천광역시', name_en: 'Incheon' },
    { code: 'KR-29', name_kr: '광주광역시', name_en: 'Gwangju' },
    { code: 'KR-30', name_kr: '대전광역시', name_en: 'Daejeon' },
    { code: 'KR-31', name_kr: '울산광역시', name_en: 'Ulsan' },
    { code: 'KR-50', name_kr: '세종특별자치시', name_en: 'Sejong' },
    { code: 'KR-41', name_kr: '경기도', name_en: 'Gyeonggi' },
    { code: 'KR-42', name_kr: '강원특별자치도', name_en: 'Gangwon' },
    { code: 'KR-43', name_kr: '충청북도', name_en: 'North Chungcheong' },
    { code: 'KR-44', name_kr: '충청남도', name_en: 'South Chungcheong' },
    { code: 'KR-45', name_kr: '전라북도', name_en: 'North Jeolla' },
    { code: 'KR-46', name_kr: '전라남도', name_en: 'South Jeolla' },
    { code: 'KR-47', name_kr: '경상북도', name_en: 'North Gyeongsang' },
    { code: 'KR-48', name_kr: '경상남도', name_en: 'South Gyeongsang' },
    { code: 'KR-49', name_kr: '제주특별자치도', name_en: 'Jeju' },
  ],
}
